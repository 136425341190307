import React, { useState } from "react";
import { BillingPeriodUnit, Plan } from "services/graphql";
import { useIsSmallScreen } from "hooks/index";
import { PeriodUnitAdverbs } from "constants/cart";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import styles from "./styles.module.scss";
import { LogoRatings } from "./LogoRatings";
import { ConfirmPlanModal } from "../ConfirmPlanModal";
import { PlanCard } from "../PlanCard";

interface Props {
  headerText: string;
  plans: Plan[];
  selectedBillingPeriod: BillingPeriodUnit;
  setSelectedBillingPeriod(billingPeriod: BillingPeriodUnit): void;
}

export function PlanSelect({
  headerText,
  plans,
  selectedBillingPeriod,
  setSelectedBillingPeriod,
}: Props) {
  const isSmallScreen = useIsSmallScreen();
  const { primarySubscription } = usePrimarySubscription();
  const { plan: currentPlan } = primarySubscription || {};
  const [planToConfirm, setPlanToConfirm] = useState<Plan | null>(null);

  if (!plans || !currentPlan) {
    return <LoaderCentered />;
  }

  const selectPlan = (plan: Plan) => {
    setPlanToConfirm(plan);
  };

  const billingAdverb =
    PeriodUnitAdverbs[currentPlan.periodUnit as keyof typeof PeriodUnitAdverbs];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.planSelectContainer}>
          <div className={styles.headerContainer}>
            {isSmallScreen ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <LogoRatings />
                <div className={styles.flexBetweenContainer}>
                  <h1 className={styles.header}>{headerText}</h1>
                </div>
              </div>
            ) : (
              <div className={styles.flexBetweenContainer}>
                <h1 className={styles.header}>{headerText}</h1>
                <LogoRatings />
              </div>
            )}
            <div
              className={styles.flexBetweenContainer}
              style={{ marginTop: "12px" }}
            >
              <h2 className={styles.subheader}>Manage Your Plan Below</h2>
              <div className={styles.billingPeriodButtonContainer}>
                <button
                  type="button"
                  className={
                    selectedBillingPeriod === BillingPeriodUnit.Month
                      ? styles.selectedBillingPeriodButton
                      : styles.billingPeriodButton
                  }
                  onClick={() =>
                    setSelectedBillingPeriod(BillingPeriodUnit.Month)
                  }
                >
                  Monthly
                </button>
                <button
                  type="button"
                  className={
                    selectedBillingPeriod === BillingPeriodUnit.Year
                      ? styles.selectedBillingPeriodButton
                      : styles.billingPeriodButton
                  }
                  onClick={() =>
                    setSelectedBillingPeriod(BillingPeriodUnit.Year)
                  }
                >
                  Yearly
                </button>
              </div>
            </div>
            <h4 className={styles.h4}>
              Your Current Plan: {billingAdverb} {currentPlan.accessType}
            </h4>
          </div>
          <div className={styles.plansContainer}>
            {plans &&
              plans.map(plan => {
                const isCurrentPlan = currentPlan.id === plan.id;
                return (
                  <PlanCard
                    isToggled={isCurrentPlan}
                    onClick={() => selectPlan(plan)}
                    toggledText="Current Plan"
                    plan={plan}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <ConfirmPlanModal
        plan={planToConfirm}
        closeModal={() => setPlanToConfirm(null)}
      />
    </>
  );
}
